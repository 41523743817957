export const cookiesConfig = [
  {
    keys: ["app_id", "pdfId", "address_token", "amplitude_user_id"],
    expires: 30 * 24 * 60 * 60 * 1000,
    description: "expires in a month",
  },
  {
    keys: ["site_info", "testAB"],
    expires: 60 * 60 * 1000,
    description: "expires in one hour",
  },
  {
    keys: ["order_status"],
    expires: 15 * 60 * 1000,
    description: "expires in 15 minutes",
  },
];
